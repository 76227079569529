import get from 'lodash/get'

import { GENDER_TYPES, ORG_TYPES, RegExps } from '../constants'

export const formatName = user => {
  if (user?.name) {
    return user.name
  }

  return [user?.firstname, user?.lastname]
    .filter(Boolean)
    .reduce((acc, curr) => (acc ? `${acc} ${curr}` : curr), '')
}

export const formatUser = userResponse => {
  let type = -1
  const roles = get(userResponse, 'userContext.roles', [])

  if (roles && roles.length > 0) {
    const types = roles
      .filter(r => r.orgType !== ORG_TYPES.Individual)
      .map(role => role.type)
    type = Math.min(...types)
  }

  const user = get(userResponse, 'userContext.user', {})

  return {
    id: userResponse.id,
    userId: userResponse.userId,
    type,
    username: user.username || '',
    name: formatName(user),
    email: user.email || '',
    mobile: user.mobile || '',
    created: user.created || '',
    region: user.region || '',
  }
}

export const formatUserRole = userResponse => {
  let type = -1
  const roles = get(userResponse, 'roles', [])

  if (roles && roles.length > 0) {
    const types = roles
      .filter(r => r.orgType !== ORG_TYPES.Individual)
      .map(role => role.type)
    type = Math.min(...types)
  }

  return {
    id: userResponse?.id,
    userId: userResponse?.Id,
    name: formatName(userResponse),
    type,
    ...userResponse,
  }
}

export const formatNameWithSurcharge = (performer, isLong = true) => {
  const { alias, subject, surcharge } = performer

  if (alias) {
    if (Number(surcharge) > 0) {
      return isLong
        ? `${alias} ($${Number(surcharge)} surcharge)`
        : `${alias} *`
    }

    return alias
  }

  if (subject) {
    const { firstname } = subject

    if (firstname) {
      if (Number(surcharge) > 0) {
        return isLong
          ? `${firstname} ($${Number(surcharge)} surcharge)`
          : `${firstname} *`
      }

      return firstname
    }
  }

  return ''
}

export const filterPerformerSkills = (performerSkills, gender) => {
  if (!performerSkills.length) return performerSkills

  switch (Number(gender)) {
    case GENDER_TYPES.Male: {
      return performerSkills.filter(skill => !/\bfemale\b/i.test(skill.name))
    }

    case GENDER_TYPES.Female: {
      return performerSkills.filter(skill => !/\bmale\b/i.test(skill.name))
    }

    default:
      return performerSkills
  }
}

export const GetHighestRoleFromProfile = useProfile => {
  let type = -1
  const roles = get(useProfile, 'roles', [])

  if (roles && roles.length > 0) {
    const types = roles.map(role => role.type)
    type = Math.min(...types)
  }

  return type
}

export const validateUsername = value => {
  if (!value) {
    return Promise.reject(new Error('Username is required'))
  }

  if (value.length < 3 || value.length > 26) {
    return Promise.reject(
      new Error('Username must be between 3 to 26 characters')
    )
  }

  if (!/^[a-z]/gi.test(value)) {
    return Promise.reject(new Error('Username must begin with alpha character'))
  }

  if (!/[a-z0-9]$/gi.test(value)) {
    return Promise.reject(
      new Error('Username must end in alphanumeric character')
    )
  }

  if (!/^[a-z0-9._@+-]+$/gi.test(value)) {
    return Promise.reject(new Error('Special characters allowed: -._@+'))
  }

  if (/^(.*[-._@+]{2}).*$/g.test(value)) {
    return Promise.reject(
      new Error('Username must not contain consecutive special characters')
    )
  }

  return Promise.resolve()
}

export const validateConfirmedPassword = (value, passwordValue) => {
  if (!value) {
    return Promise.reject(new Error('Password confirm is required'))
  }

  if (value && value !== passwordValue) {
    return Promise.reject(
      new Error('Two passwords that you enter are inconsistent')
    )
  }

  return Promise.resolve()
}

export const validatePassword = value => {
  if (!value) {
    return Promise.reject(new Error('Password is required'))
  }

  if (value.length < 8) {
    return Promise.reject(new Error('Password must be at least 8 characters'))
  }

  if (value.length > 100) {
    return Promise.reject(
      new Error('Password cannot be longer than 100 characters')
    )
  }

  if (!new RegExp('(?=.*[a-z])').test(value)) {
    return Promise.reject(
      new Error(
        'Password must contain at least 1 lowercase alphabetical character'
      )
    )
  }

  if (!new RegExp('(?=.*[A-Z])').test(value)) {
    return Promise.reject(
      new Error(
        'Password must contain at least 1 uppercase alphabetical character'
      )
    )
  }

  if (!RegExp('(?=.*[0-9])').test(value)) {
    return Promise.reject(
      new Error('Password must contain at least 1 numeric character')
    )
  }

  if (!new RegExp('(?=.*[_!@#$%^&*])').test(value)) {
    // eslint-disable-line
    return Promise.reject(
      new Error('Password must contain at least 1 special character')
    )
  }

  return Promise.resolve()
}

// Auto complete Tax field

// 000 00 0000
export const autoCompleteSSNInput = (value = '') => {
  const valueMatch = []
  const valueReplaced = value.replace(RegExps.Hyphen, '')
  valueMatch.push(
    ...(valueReplaced.slice(0, 3).match(RegExps.OneToThreeCharacters) || [])
  )
  valueMatch.push(
    ...(valueReplaced.slice(3, 5).match(RegExps.OneToTwoCharacters) || [])
  )
  valueMatch.push(
    ...(valueReplaced.slice(5).match(RegExps.OneToFourCharacters) || [])
  )

  return valueMatch.join('-')
}

// 000 000 000
export const autoCompleteSINInput = (value = '') =>
  value
    .replace(RegExps.WhiteSpace, '')
    .match(RegExps.OneToThreeCharacters)
    ?.join(' ') || ''

// 00 000 000 000
export const autoCompleteABNInput = (value = '') => {
  const valueMatch = []
  const valueReplaced = value.replace(RegExps.WhiteSpace, '')
  valueMatch.push(
    ...(valueReplaced.slice(0, 2).match(RegExps.OneToTwoCharacters) || [])
  )
  valueMatch.push(
    ...(valueReplaced.slice(2).match(RegExps.OneToThreeCharacters) || [])
  )

  return valueMatch.join(' ')
}

export const BusinessNumberMaxLength = {
  SSN: 11,
  ABN: 14,
  BusinessNumber: 9,
  SIN: 11,
}
