import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import { action, computed, observable, runInAction } from 'mobx'
import { persist } from 'mobx-persist'
import moment from 'moment'

import {
  BookingCode,
  BookingQuoteStagesPaths,
  BookingType,
  CeremonyMinHourByDistance,
  EnquiryWord,
  EventType,
  InitialHourBooking,
  MOMENT_DATE_FORMAT,
  NO_CEREMONY,
  NO_CEREMONY_OBJECT,
  NO_RECEPTION,
  NO_RECEPTION_OBJECT,
  PerformanceType,
  PhoneType,
  SubmitEnquiryType,
  VocalOption,
  WebsiteUrl,
} from '@/constants'
import { LONG_DATE_FORMAT } from '@/flynk.app.web.core.components/constants'
import REGIONS from '@/flynk.app.web.core.components/constants/regions'
import { RESPONSE_TYPES } from '@/flynk.app.web.core.data/constants'
import { DEFAULT_PAGINATION } from '@/flynk.app.web.core.data/constants/pagination'
import { isEmptyObject } from '@/flynk.app.web.core.data/helpers'
import {
  getUpdateSelectedPerformersOptions,
  handleQuoteBuilderError,
  mergeVocalOptions,
} from '@/helpers/booking'
import { postAMessageFromIframeToParent, toCapitalize } from '@/helpers/common'
import { formatName } from '@/helpers/user'

let savedCityId

try {
  savedCityId = localStorage.getItem('savedCityId') || ''
} catch (err) {
  savedCityId = ''
}

const isNotAllowedUpdateBooking = ({ code, type }) =>
  code === BookingCode.NotAllowedUpdating && type === BookingType.Warning

const isInvalidResponse = ({ code }) => code === BookingCode.InvalidResponse

export default class BookingStore {
  constructor(args) {
    this.rootStore = args.rootStore
    this.rootAPI = args.rootAPI
  }

  @persist @observable isFirstTimeViewBookingPage = true

  @observable curFirstTimeViewBookingPage = false

  @action.bound
  toggleFirstTimeViewBookingPage() {
    this.curFirstTimeViewBookingPage = true
    this.isFirstTimeViewBookingPage = false
  }

  // Routing
  @action.bound
  push(path) {
    let newUrl = path

    if (this.cityId) {
      newUrl = `${path}?cityId=${this.cityId}`
    }

    this.rootStore.routingStore.push(newUrl)
  }

  // Context
  @observable createContextLoading = false

  @observable getContextTimes = 0

  @persist('list') @observable cities = []

  @persist('list') @observable regions = []

  @observable pagination = DEFAULT_PAGINATION

  @computed get selectedCity() {
    return this.cities.find(city => city.selected) || {}
  }

  @computed get cityId() {
    return this.selectedCity.id || savedCityId
  }

  @computed get regionId() {
    return this.selectedCity.regionId || ''
  }

  @computed get regionName() {
    const name = get(this.selectedCity, 'region.name', '')

    return name ? name.toLowerCase() : ''
  }

  @computed get websiteUrl() {
    const regionCode = get(this.selectedCity, 'region.name', 'AU')

    return WebsiteUrl[regionCode.toUpperCase()]
  }

  @computed get enquiryWord() {
    const regionCode = get(this.selectedCity, 'region.name', 'AU')

    return EnquiryWord[regionCode.toUpperCase()]
  }

  @action.bound
  setPagination = pagination => {
    this.pagination = pagination
  }

  @observable getRegionsAndCitiesLoading = false

  @action.bound
  async getRegionsAndCities(
    data = {
      skip: 0,
      take: this.pagination.pageSize,
    }
  ) {
    this.getRegionsAndCitiesLoading = true

    try {
      const promises = [
        this.rootAPI.bookingAPI.getRegions(data),
        this.rootAPI.bookingAPI.getCities(data),
      ]

      const [regionsRes, citiesRes] = await Promise.all(promises)
      const regions = get(regionsRes, 'payload.items', [])
      const cities = get(citiesRes, 'payload.items', [])

      if (regions.length > 0) {
        this.regions = regions
        this.setPagination({
          ...this.pagination,
          current: regionsRes.payload.page,
          pageSize: regionsRes.payload.pageSize,
          total: regionsRes.payload.totalItems,
        })
      }

      if (cities.length > 0) {
        this.cities = cities.map(city => {
          const region = this.regions.find(r => r.id === city.regionId) || {}

          // const selected = false
          // if (savedCityId) {
          //   selected = city.id === savedCityId
          // } else if (index === 0) {
          //   selected = true
          // }
          return {
            ...city,
            region,
            selected: false,
          }
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.getRegionsAndCitiesLoading = false
    }
  }

  @action.bound
  async getContext(cityId, dontRedirect) {
    try {
      const isCityIdValid = !!this.cities.find(city => city.id === cityId)

      if (isCityIdValid && cityId !== this.selectedCity.id) {
        const res = await this.rootAPI.bookingAPI.getContext(cityId)

        if (res && res.payload) {
          runInAction(() => {
            const id = get(res.payload, 'city.id', '')

            if (id) {
              this.selectCity(id, true, dontRedirect)
            }
          })
        }
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  async createContext(position) {
    if (this.getContextTimes === 0) {
      const data = {}

      if (position?.coords) {
        data.location = {}
        data.location.lat = position.coords.latitude
        data.location.long = position.coords.longitude
      }

      try {
        this.createContextLoading = true

        if (savedCityId) {
          await this.selectCity(savedCityId)
        } else {
          const { payload } = await this.rootAPI.bookingAPI.createContext(data)

          if (payload) {
            runInAction(() => {
              this.getContextTimes += 1
              const cityId = get(payload, 'city.id', '')

              if (cityId) {
                this.selectCity(cityId)
              }
            })
          }
        }
      } catch (err) {
        if (this.cities.length > 0 && this.cities[0] && this.cities[0].id) {
          await this.selectCity(this.cities[0].id)
        }
      } finally {
        this.createContextLoading = false
      }
    }
  }

  @observable isDisabledCityDropdown = false

  @action.bound
  setIsDisabledCityDropdown(value) {
    this.isDisabledCityDropdown = value
  }

  @action.bound
  async selectCity(id, isManually) {
    if (isManually) {
      this.toggleOpenedWeddingLocationModal()
    }

    const selectedCityId = this.selectedCity.id

    if (selectedCityId !== id) {
      const currentBookingInfo = {
        booking: this.booking,
        quote: this.quote,
        date: this.date,
        notSureWhen: this.notSureWhen,
        ceremonyLocation: this.ceremonyLocation,
        receptionLocation: this.receptionLocation,
        ceremonyPlaceName: this.ceremonyPlaceName,
        receptionPlaceName: this.receptionPlaceName,
        ceremonyRawData: this.ceremonyRawData,
        receptionRawData: this.receptionRawData,
        over178kmMessage: this.over178kmMessage,
        over500kmMessage: this.over500kmMessage,
        notSureCeremonyLocation: this.notSureCeremonyLocation,
        notSureReceptionLocation: this.notSureReceptionLocation,
        showLocationModalTimes: this.showLocationModalTimes,
        savedNotSureCeremonyLocation: this.savedNotSureCeremonyLocation,
        savedNotSureReceptionLocation: this.savedNotSureReceptionLocation,
        isCustomisation: this.isCustomisation,
        ceremonyPerformances: this.ceremonyPerformances,
        receptionPerformances: this.receptionPerformances,
        ceremonyPerformanceGig: this.ceremonyPerformanceGig,
        receptionPerformanceGig: this.receptionPerformanceGig,
        performers: this.performers,
        performerOrder: this.performerOrder,
        enquiry: this.enquiry,
        pathname: this.rootStore.routingStore.location.pathname,
        note: this.eventNote,
        selectedPerformers: this.selectedPerformers,
      }

      this.cities = this.cities.map(city => {
        // save current booking info
        if (city.selected) {
          return {
            ...city,
            selected: false,
            bookingInfo: currentBookingInfo,
          }
        }

        if (city.id === id) {
          // load existing booking info
          if (city.bookingInfo) {
            this.booking = city.bookingInfo.booking
            this.quote = city.bookingInfo.quote
            this.date = city.bookingInfo.date
            this.notSureWhen = city.bookingInfo.notSureWhen
            this.ceremonyLocation = city.bookingInfo.ceremonyLocation
            this.receptionLocation = city.bookingInfo.receptionLocation
            this.ceremonyPlaceName = city.bookingInfo.ceremonyPlaceName
            this.receptionPlaceName = city.bookingInfo.receptionPlaceName
            this.ceremonyRawData = city.bookingInfo.ceremonyRawData
            this.receptionRawData = city.bookingInfo.receptionRawData
            this.over178kmMessage = city.bookingInfo.over178kmMessage
            this.over500kmMessage = city.bookingInfo.over500kmMessage
            this.notSureCeremonyLocation =
              city.bookingInfo.notSureCeremonyLocation
            this.notSureReceptionLocation =
              city.bookingInfo.notSureReceptionLocation
            this.showLocationModalTimes =
              city.bookingInfo.showLocationModalTimes
            this.savedNotSureCeremonyLocation =
              city.bookingInfo.savedNotSureCeremonyLocation
            this.savedNotSureReceptionLocation =
              city.bookingInfo.savedNotSureReceptionLocation
            this.isCustomisation = city.bookingInfo.isCustomisation
            this.ceremonyPerformanceGig =
              city.bookingInfo.ceremonyPerformanceGig
            this.receptionPerformanceGig =
              city.bookingInfo.receptionPerformanceGig
            this.performers = city.bookingInfo.performers
            this.performerOrder = city.bookingInfo.performerOrder
            this.enquiry = city.bookingInfo.enquiry
            this.eventNote = city.bookingInfo.note
            this.selectedPerformers = city.bookingInfo.selectedPerformers
          } else {
            const option = { clearPerformances: false, clearPerformers: true }

            if (savedCityId === id) {
              option.clearPerformers = false
            }

            this.clear(option)
          }

          localStorage.setItem('savedCityId', id)

          return {
            ...city,
            selected: true,
          }
        }

        return {
          ...city,
          selected: false,
        }
      })

      if (this.selectedCity?.bookingInfo) {
        this.selectCeremonyPerformance(this.selectedCeremonyPerformance.id)
        this.selectReceptionPerformance(this.selectedReceptionPerformance.id)
      }

      await this.getPerformances()

      if (!this.performers.length) {
        await this.getPerformers()
      }
    }
  }

  @observable isPrivateEventBooking = false

  @action.bound
  checkIsPrivateEventBooking(eventType) {
    this.isPrivateEventBooking = [
      EventType.Party,
      EventType.Corporate,
    ].includes(eventType)
  }

  @action.bound
  async selectCityByCityName(cityName, isManually) {
    const selectedCity = this.cities.find(city =>
      cityName.toUpperCase().includes(city.name.toUpperCase())
    )

    if (selectedCity) {
      await this.selectCity(selectedCity?.id, isManually)
    } else {
      this.rootStore.errorsStore.addError({
        description: 'Not found city, please double check city name',
      })
    }
  }

  // Create Booking
  @observable relationships = [
    'Bride',
    'Groom',
    'Partner',
    'Parent',
    'Event Planner',
    'Other',
  ]

  @observable customerInfo = {
    name: '',
    mobile: '',
  }

  @action.bound
  setCustomerInfo({ name, mobile, region }) {
    this.customerInfo = {
      name,
      mobile,
    }
    this.region = region
  }

  @persist('object') @observable booking = {}

  @persist('list') @observable performanceTypes = []

  @observable createBookingLoading = false

  @observable updateBookingLoading = false

  @computed get isValidBooking() {
    return !!this.booking.id
  }

  @observable getPerformanceTypeLoading = false

  @action.bound
  async getPerformanceTypes() {
    this.getPerformanceTypeLoading = true

    try {
      if (this.performanceTypes.length === 0) {
        const { payload } =
          await this.rootAPI.performanceAPI.getPerformanceTypes()

        if (payload) {
          this.performanceTypes = payload
        }
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.getPerformanceTypeLoading = false
    }
  }

  @action.bound
  async createBooking(data, isRequestCallback = false) {
    this.createBookingLoading = true
    let res = null

    try {
      if (!this.cityId) {
        await this.createContext()
      }

      if (data?.region) {
        this.region = data.region
      }

      const currentSelectedData = this.getRequestDataForBooking()
      res = await this.rootAPI.bookingAPI.createBooking({
        ...data,
        regionId: this.regionId,
        cityId: this.cityId,
        isRequestCallback,
        note: this.eventNote,
        gaCustomerId: window?.gaGlobal?.vid ?? '',
        ...currentSelectedData,
      })

      if (data?.email) {
        const formattedData = res.payload
        this.setQuote(get(formattedData, 'quote', {}))

        formattedData.customer = {
          ...formattedData.customer,
          name: formatName(formattedData.customer),
        }

        formattedData.eventNote = formattedData.note
        runInAction(() => {
          this.booking = formattedData
        })
      } else {
        this.setCustomerInfo({
          name: data.name,
          mobile: data.mobile,
          region: data.region,
        })
      }

      await this.getPerformances()
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.createBookingLoading = false
    }

    return res
  }

  getRequestDataForBooking() {
    const dataBooking = {}

    try {
      const date = this.date
        ? moment(this.date, LONG_DATE_FORMAT).format(
            `${MOMENT_DATE_FORMAT}T00:00:00`
          )
        : null
      dataBooking.eventTime = {
        eventDate: date,
        notSureWhen: date ? this.notSureWhen : true,
      }

      const { performerIds: performers } = getUpdateSelectedPerformersOptions(
        null,
        this.selectedPerformers
      )

      dataBooking.performers = performers
      dataBooking.performances = []
      const ceremonyRawData = !this.ceremonyRawData
        ? ''
        : JSON.stringify({
            ...this.ceremonyRawData,
            name: this.ceremonyPlaceName,
          })

      if (this.selectedCeremonyPerformance?.id || ceremonyRawData) {
        dataBooking.noCeremony =
          this.selectedCeremonyPerformance?.id === NO_CEREMONY

        dataBooking.performances.push({
          performanceId: !dataBooking.noCeremony
            ? this.selectedCeremonyPerformance?.id
            : null,
          performanceTypeId: this.ceremonyTypeId,
          venue: {
            location: ceremonyRawData,
            cityId: this.selectedCity.id,
          },
          duration: this.ceremonyDuration,
        })
      }

      const receptionRawData = !this.receptionRawData
        ? ''
        : JSON.stringify({
            ...this.receptionRawData,
            name: this.receptionPlaceName,
          })

      if (this.selectedReceptionPerformance?.id || receptionRawData) {
        dataBooking.noReception =
          this.selectedReceptionPerformance?.id === NO_RECEPTION

        dataBooking.performances.push({
          performanceId: !dataBooking.noReception
            ? this.selectedReceptionPerformance?.id
            : null,
          performanceTypeId: this.receptionTypeId,
          venue: {
            location: receptionRawData,
            cityId: this.selectedCity.id,
          },
          duration: this.receptionDuration,
        })
      }
    } catch (e) {
      this.rootStore.errorsStore.addError(e)
    }

    return dataBooking
  }

  @action.bound
  async updateBooking(data, isRequestCallback = false) {
    const phoneType = sessionStorage.getItem('phoneType') || PhoneType.Mobile

    if (!isEmptyObject(this.booking)) {
      const { customer, relationship } = this.booking

      if (
        data.name === customer.name &&
        data.email === customer.email &&
        data.relationship === relationship &&
        ((String(data.mobile) === String(customer.mobile) &&
          phoneType === PhoneType.Mobile) ||
          (String(data.landline) === String(customer.landline) &&
            phoneType === PhoneType.Other))
      ) {
        return
      }
    }

    this.updateBookingLoading = true

    try {
      if (!this.cityId) {
        await this.createContext()
      }

      if (data?.region) {
        this.region = data.region
      }

      const primaryContact = this.booking.customerContacts.find(
        c => c.isPrimary
      )
      const info = {
        ...data,
        id: primaryContact.id,
      }
      await this.rootAPI.bookingAPI.updateBooking(this.booking.id, {
        customerInfos: [info],
        isRequestCallback,
      })

      runInAction(() => {
        this.booking = {
          ...this.booking,
          customer: {
            ...this.booking.customer,
            ...info,
          },
          relationship: info.relationship,
        }
      })
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.updateBookingLoading = false
    }
  }

  @action.bound
  async updateNotes(eventNote) {
    this.eventNote = eventNote
    this.isEventNoteChanged = true

    if (!this.booking?.id) {
      return
    }

    this.updateBookingLoading = true

    try {
      const res = await this.rootAPI.bookingAPI.updateBookingNote(
        this.booking.id,
        {
          note: eventNote,
        }
      )

      if (isNotAllowedUpdateBooking(res)) {
        return this.createBooking({
          ...this.booking.customer,
          region: this.region,
          isSubmit: false,
        }).then(bookingResponse => {
          if (!isInvalidResponse(bookingResponse) || bookingResponse?.payload) {
            this.shouldUpdateBookingOnEnquiry = true

            return this.updateNotes(eventNote)
          }
        })
      }

      runInAction(() => {
        this.booking.eventNote = eventNote
      })
    } catch (e) {
      this.rootStore.errorsStore.addError(e)
    } finally {
      this.updateBookingLoading = false
    }
  }

  // Events, Quotes
  @computed get event() {
    return this.booking.event || {}
  }

  @persist('object') @observable quote = {}

  @action.bound
  setQuote(quote) {
    this.quote = quote
  }

  // Date Time
  @persist @observable date = ''

  @persist @observable notSureWhen = false

  @observable updateEventTimeLoading = false

  @computed get isValidDate() {
    return !!(this.date || this.notSureWhen)
  }

  @action.bound
  setDate(date) {
    this.date = date
  }

  @action.bound
  toggleNotSureWhen(value) {
    this.notSureWhen = value
  }

  @action.bound
  async updateEventTime() {
    // if (!this.isValidDate) {
    //   this.rootStore.errorsStore.addError({
    //     description: 'Please select a date or "not sure when"',
    //   })
    //   return
    // }
    const date = this.date
      ? moment(this.date, LONG_DATE_FORMAT).format(
          `${MOMENT_DATE_FORMAT}T00:00:00`
        )
      : null

    if (!this.booking?.id) {
      return
    }

    try {
      this.updateEventTimeLoading = true
      const res = await this.rootAPI.bookingAPI.updateEventTime(this.event.id, {
        eventDate: date,
        notSureWhen: date ? this.notSureWhen : true,
      })

      if (isNotAllowedUpdateBooking(res)) {
        return this.createBooking({
          ...this.booking.customer,
          region: this.region,
          isSubmit: false,
        }).then(bookingResponse => {
          if (!isInvalidResponse(bookingResponse) || bookingResponse?.payload) {
            this.shouldUpdateBookingOnEnquiry = true

            return this.updateEventTime()
          }
        })
      }

      if (res && res.payload) {
        runInAction(() => {
          this.setQuote(res.payload)
        })
      }

      await this.getPerformances()
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.updateEventTimeLoading = false
    }
  }

  // Locations
  @computed get ceremonyType() {
    return (
      this.performanceTypes.find(
        type => type.name === PerformanceType.Ceremony
      ) || {}
    )
  }

  @computed get receptionType() {
    return (
      this.performanceTypes.find(
        type => type.name === PerformanceType.Reception
      ) || {}
    )
  }

  @computed get ceremonyTypeId() {
    return this.ceremonyType.id || ''
  }

  @computed get receptionTypeId() {
    return this.receptionType.id || ''
  }

  @computed get ceremonyGig() {
    const gigs = get(this.booking, 'event.gigs', [])

    if (gigs.length) {
      const gig = gigs.find(
        g => get(g, 'performanceTypeId', '') === this.ceremonyTypeId
      )

      if (gig) {
        return gig
      }
    }

    return {}
  }

  @computed get receptionGig() {
    const gigs = get(this.booking, 'event.gigs', [])

    if (gigs.length) {
      const gig = gigs.find(
        g => get(g, 'performanceTypeId', '') === this.receptionTypeId
      )

      if (gig) {
        return gig
      }
    }

    return {}
  }

  @persist @observable ceremonyLocation = ''

  @persist @observable receptionLocation = ''

  @persist('object') @observable ceremonyRawData = null

  @persist('object') @observable receptionRawData = null

  @persist @observable ceremonyPlaceName = null

  @persist @observable receptionPlaceName = null

  @observable updateGigVenueLoading = false

  @observable over178kmMessage = ''

  @observable over500kmMessage = ''

  @persist @observable notSureCeremonyLocation = false

  @persist @observable notSureReceptionLocation = false

  @persist @observable showLocationModalTimes = 0

  @persist @observable savedNotSureCeremonyLocation = false

  @persist @observable savedNotSureReceptionLocation = false

  @persist @observable ceremonyDuration = 1

  @persist @observable receptionDuration = 5

  @persist @observable shouldUpdateBookingOnEnquiry = true

  @persist @observable region = REGIONS.AU

  @computed get isValidLocations() {
    return !!(
      this.notSureCeremonyLocation ||
      this.notSureReceptionLocation ||
      this.ceremonyRawData ||
      this.receptionRawData
    )
  }

  @observable ceremonyMapObject = {}

  @observable receptionMapObject = {}

  @action.bound
  setCeremonyMapObject(mapObject) {
    this.ceremonyMapObject = mapObject
  }

  @action.bound
  setReceptionMapObject(mapObject) {
    this.receptionMapObject = mapObject
  }

  @action.bound
  async setPerformanceLocation(
    location,
    geoCodedPrediction,
    placeName,
    performanceType
  ) {
    // performanceType = ceremony || reception

    const isCeremony = performanceType === 'ceremony'

    if (isCeremony) {
      await this.checkCeremonyBookingMinDuration(geoCodedPrediction)
    }

    this[`${performanceType}Location`] = location
    this[`${performanceType}RawData`] = geoCodedPrediction
    this[`${performanceType}PlaceName`] = placeName

    // location && !rawData means user inputs text but haven't selected location
    if (!this.booking?.id || (location && !geoCodedPrediction)) {
      return
    }

    await this.updateGigVenue(performanceType)
    await this.updateGigDuration(
      performanceType,
      this[`${performanceType}Duration`]
    )

    if (!geoCodedPrediction?.address_components?.length) return

    const country = geoCodedPrediction.address_components.find(
      addressComponent => addressComponent?.types?.indexOf('country')
    )

    const cityByCountry = this.cities.find(
      city => city?.region?.name === country?.short_name
    )

    if (cityByCountry) {
      this.selectCity(cityByCountry?.id, true, true)
    }
  }

  @action.bound
  callAPIUpdateGigVenue(type) {
    const typeCapitalized = toCapitalize(type)
    const responses = []
    const rawData = !this[`${type}RawData`]
      ? ''
      : JSON.stringify({
          ...this[`${type}RawData`],
          name: this[`${type}PlaceName`],
        })

    if (!isEmptyObject(this[`selected${typeCapitalized}Performance`])) {
      responses.push(
        this.rootAPI.bookingAPI.updateGigVenue({
          id: this[`${type}Gig`].id,
          location: rawData,
          cityId: this.cityId,
        })
      )

      if (
        this[`savedNotSure${typeCapitalized}Location`] !==
        this[`notSure${typeCapitalized}Location`]
      ) {
        responses.push(
          this.rootAPI.bookingAPI.updateGigNotSureWhere({
            id: this[`${type}Gig`].id,
            notSureWhere: this[`notSure${typeCapitalized}Location`],
          })
        )

        this[`savedNotSure${typeCapitalized}Location`] =
          this[`notSure${typeCapitalized}Location`]
      }
    }

    return Promise.all(responses)
  }

  @action.bound
  async updateGigVenue(gigType) {
    try {
      this.updateGigVenueLoading = true

      const responses = await this.callAPIUpdateGigVenue(gigType)

      if (!responses?.length) return

      const messages = responses.filter(response => response?.message)

      const hasNotAllowedUpdateBooking = responses.some(response =>
        isNotAllowedUpdateBooking(response)
      )

      if (hasNotAllowedUpdateBooking) {
        return this.createBooking({
          ...this.booking.customer,
          region: this.region,
          isSubmit: false,
        }).then(bookingResponse => {
          if (!isInvalidResponse(bookingResponse) || bookingResponse?.payload) {
            this.shouldUpdateBookingOnEnquiry = true

            return this.updateGigVenue(gigType)
          }
        })
      }

      const [quoteResponse] = responses

      if (quoteResponse?.payload) {
        this.setQuote(quoteResponse.payload)
      }

      await this.getPerformances()

      this.over500kmMessage = (
        messages.find(r => r.type === 0) || { message: '' }
      ).message
      this.over178kmMessage = (
        messages.find(r => r.type > 0) || { message: '' }
      ).message

      if (this.over500kmMessage) {
        this.rootStore.alertStore.info({
          title: 'Info',
          content: this.over500kmMessage,
          cancelText: null,
          okText: 'Continue',
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.updateGigVenueLoading = false
    }
  }

  @action.bound
  async toggleNotSureLocation({ type, value }) {
    if (type === 'ceremony' && this.ceremonyGig.id) {
      this.notSureCeremonyLocation = value
    }

    if (type === 'reception' && this.receptionGig.id) {
      this.notSureReceptionLocation = value
    }

    if (this.showLocationModalTimes === 0) {
      this.showLocationModalTimes += 1
      this.rootStore.alertStore.info({
        title: 'Info',
        content:
          "Haven't booked a venue yet? We will display our local pricing that doesn't include any travel fees.",
      })
    }
  }

  // Customization
  @persist @observable isCustomisation = false

  @persist('list') @observable ceremonyPerformances = []

  @persist('list') @observable receptionPerformances = []

  @persist('object') @observable ceremonyPerformancesCache = {}

  @persist('object') @observable receptionPerformancesCache = {}

  @persist('object') @observable ceremonyPerformanceGig = {}

  @persist('object') @observable receptionPerformanceGig = {}

  @observable selectPerformanceLoading = false

  @observable getPerformancesLoading = false

  @observable selectCeremonyPerformanceLoading = false

  @observable selectReceptionPerformanceLoading = false

  @observable currentCeremonySliderKey = 'initial'

  @observable currentReceptionSliderKey = 'initial'

  @action.bound
  toggleCustomisation({ visible }) {
    this.isCustomisation = visible
  }

  @computed get selectedCeremonyPerformance() {
    return (
      this.ceremonyPerformances.find(performance => performance.selected) || {}
    )
  }

  @computed get selectedReceptionPerformance() {
    return (
      this.receptionPerformances.find(performance => performance.selected) || {}
    )
  }

  @computed get cIndex() {
    return this.selectedCeremonyPerformance.order || 0
  }

  @computed get rIndex() {
    return this.selectedReceptionPerformance.order || 0
  }

  @observable lastSelectedCeremonyId = ''

  @action.bound
  async selectCeremonyPerformance(id) {
    this.selectCeremonyPerformanceLoading = true

    try {
      this.ceremonyPerformances = this.ceremonyPerformances.map(
        performance => ({
          ...performance,
          selected: performance.id === id,
        })
      )

      let performanceId = null

      if (
        !isEmptyObject(this.selectedCeremonyPerformance) &&
        id !== NO_CEREMONY
      ) {
        performanceId = id
      }

      if (this.ceremonyRawData?.geometry?.location) {
        await this.checkCeremonyBookingMinDuration(this.ceremonyRawData)
      } else {
        await this.resetMinimumBookingHoursOnCeremony()
      }

      if (this.booking?.id) {
        await this.selectPerformance({ type: 'ceremony', performanceId })
        await this.updateGigVenue('ceremony')
        await this.updateGigDuration('ceremony', this.ceremonyDuration)
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.selectCeremonyPerformanceLoading = false
    }
  }

  @action.bound
  async selectReceptionPerformance(id) {
    this.selectReceptionPerformanceLoading = true

    try {
      this.receptionPerformances = this.receptionPerformances.map(
        performance => ({
          ...performance,
          selected: performance.id === id,
        })
      )
      let performanceId = null

      if (
        !isEmptyObject(this.selectedReceptionPerformance) &&
        id !== NO_RECEPTION
      ) {
        performanceId = id
      }

      if (this.booking?.id) {
        await this.selectPerformance({ type: 'reception', performanceId })
        await this.updateGigVenue('reception')
        await this.updateGigDuration('reception', this.receptionDuration)
      }

      if (id === NO_RECEPTION) {
        if (this.ceremonyRawData?.geometry?.location) {
          await this.checkCeremonyBookingMinDuration(this.ceremonyRawData)
          await this.updateGigDuration('ceremony', this.ceremonyDuration)
        }
      } else {
        await this.resetMinimumBookingHoursOnCeremony()
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.selectReceptionPerformanceLoading = false
    }
  }

  @action.bound
  async selectPerformance({ type, performanceId }) {
    const id = this[`${type}Gig`].id || this[`${type}PerformanceGig`].id

    this.selectPerformanceLoading = true

    try {
      if (id) {
        let res

        if (performanceId) {
          res = await this.rootAPI.bookingAPI.selectPerformance({
            id,
            performanceId,
          })
        } else {
          res = await this.rootAPI.bookingAPI.deselectPerformance({
            id,
          })
        }

        if (isNotAllowedUpdateBooking(res)) {
          return this.createBooking({
            ...this.booking.customer,
            region: this.region,
            isSubmit: false,
          }).then(bookingResponse => {
            if (
              !isInvalidResponse(bookingResponse) ||
              bookingResponse?.payload
            ) {
              this.shouldUpdateBookingOnEnquiry = true

              return this.selectPerformance({ type, performanceId })
            }
          })
        }

        const data = (res && res.payload) || {}
        runInAction(() => {
          this.getPerformances()
          this.setQuote(data)
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.selectPerformanceLoading = false
    }
  }

  @action.bound
  async getPerformances() {
    try {
      this.getPerformancesLoading = true

      if (!this.performanceTypes.length) {
        await this.getPerformanceTypes()
      }

      const params = {
        cityId: this.cityId,
        eventDate: this.date
          ? moment(this.date, LONG_DATE_FORMAT).format(
              `${MOMENT_DATE_FORMAT}T00:00:00`
            )
          : null,
      }

      const ceremonyRawLocationData =
        !this.ceremonyRawData ||
        this.selectedCeremonyPerformance?.id === NO_CEREMONY
          ? ''
          : JSON.stringify({
              ...this.ceremonyRawData,
              name: this.ceremonyPlaceName,
            })

      const receptionRawLocationData =
        !this.receptionRawData ||
        this.selectedReceptionPerformance?.id === NO_RECEPTION
          ? ''
          : JSON.stringify({
              ...this.receptionRawData,
              name: this.receptionPlaceName,
            })

      const promises = [
        this.rootAPI.bookingAPI.getPerformances(
          {
            performanceTypeId: this.ceremonyTypeId,
            duration: this.ceremonyDuration,
            ...params,
          },
          [ceremonyRawLocationData, receptionRawLocationData]
        ),
        this.rootAPI.bookingAPI.getPerformances(
          {
            performanceTypeId: this.receptionTypeId,
            duration: this.receptionDuration,
            ...params,
          },
          [ceremonyRawLocationData, receptionRawLocationData]
        ),
      ]

      const [ceremonyPerformances, receptionPerformances] = await Promise.all(
        promises
      ).then(res =>
        res?.map(performanceRes =>
          performanceRes?.payload?.filter(item => item?.group !== -1)
        )
      )

      const updatePerformances = (
        performances,
        selectedPerformance,
        noPerformanceObject
      ) =>
        performances.length > 0
          ? [{ ...noPerformanceObject }, ...performances].map(performance =>
              performance.id === selectedPerformance.id
                ? { ...performance, selected: true }
                : performance
            )
          : [{ ...noPerformanceObject }, ...performances]

      runInAction(() => {
        this.ceremonyPerformances = updatePerformances(
          ceremonyPerformances,
          this.selectedCeremonyPerformance,
          NO_CEREMONY_OBJECT
        )
        this.receptionPerformances = updatePerformances(
          receptionPerformances,
          this.selectedReceptionPerformance,
          NO_RECEPTION_OBJECT
        )
        this.ceremonyPerformancesCache[this.cityId] = {
          ...ceremonyPerformances,
        }
        this.receptionPerformancesCache[this.cityId] = {
          ...receptionPerformances,
        }
      })
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.getPerformancesLoading = false
    }
  }

  // Customized Notification Modal
  @observable notificationModalVisible = false

  @observable notificationModalContent = {
    title: '',
    imgSrc: '',
    content: '',
    buttonLabel: 'OK',
  }

  @action.bound
  toggleNotificationModal() {
    this.notificationModalVisible = !this.notificationModalVisible
  }

  @action.bound
  openContentNotificationModal({ title, imgSrc, content, buttonLabel }) {
    this.notificationModalContent = {
      title,
      imgSrc,
      content,
      buttonLabel,
    }
    this.notificationModalVisible = true
  }

  // Request Callback modal
  @observable requestCallbackModalVisible = false

  @observable submitRequestCallbackLoading = false

  @action.bound
  toggleRequestCallbackModalVisible() {
    this.requestCallbackModalVisible = !this.requestCallbackModalVisible
    setTimeout(() => {
      if (!this.isOpenedWeddingLocationModal) {
        this.toggleWeddingLocationModalVisible()
      }
    }, 500)
  }

  // Request Callback success Modal
  @observable requestCallbackSuccessModalVisible = false

  @action.bound
  toggleRequestCallbackSuccessModalVisible() {
    this.requestCallbackSuccessModalVisible =
      !this.requestCallbackSuccessModalVisible
  }

  // Location modal
  @observable locationModalVisible = false

  @observable submitPackageWithLocationLoading = false

  @action.bound
  toggleLocationModal({ visible }) {
    this.locationModalVisible = visible
  }

  @computed get showCeremonySummary() {
    if (
      isEmptyObject(this.selectedCeremonyPerformance) &&
      isEmptyObject(this.selectedReceptionPerformance) &&
      isEmptyObject(this.selectedCeremonyGig) &&
      isEmptyObject(this.selectedReceptionGig) &&
      this.ceremonyRawData
    ) {
      return true
    }

    if (
      !isEmptyObject(this.selectedCeremonyPerformance) &&
      this.selectedCeremonyPerformance.id !== NO_CEREMONY
    ) {
      return true
    }

    return !isEmptyObject(this.selectedCeremonyGig)
  }

  @computed get showReceptionSummary() {
    if (
      isEmptyObject(this.selectedCeremonyPerformance) &&
      isEmptyObject(this.selectedReceptionPerformance) &&
      isEmptyObject(this.selectedCeremonyGig) &&
      isEmptyObject(this.selectedReceptionGig) &&
      this.receptionRawData
    ) {
      return true
    }

    if (
      !isEmptyObject(this.selectedReceptionPerformance) &&
      this.selectedReceptionPerformance.id !== NO_RECEPTION
    ) {
      return true
    }

    return !isEmptyObject(this.selectedReceptionGig)
  }

  @computed get selectedCeremonyGig() {
    const gigs = get(this.selectedPackage, 'gigs', [])

    if (gigs.length) {
      const gig = gigs.find(
        g => g.performance && g.performance.typeId === this.ceremonyTypeId
      )

      if (gig) {
        return gig
      }
    }

    return {}
  }

  @computed get selectedReceptionGig() {
    const gigs = get(this.selectedPackage, 'gigs', [])

    if (gigs.length) {
      const gig = gigs.find(
        g => g.performance && g.performance.typeId === this.receptionTypeId
      )

      if (gig) {
        return gig
      }
    }

    return {}
  }

  // Performers
  @persist('list') @observable performers = []

  @persist @observable performerOrder = 0

  @observable getPerformersLoading = false

  @observable getSelectedPerformersLoading = false

  @persist('list') @observable selectedPerformers = []

  @observable updateSelectedPerformersLoading = false

  @observable reorderSelectedPerformersLoading = false

  @action.bound
  async clearSelectedPerformers() {
    this.selectedPerformers = []
  }

  @action.bound
  async updateSelectedPerformers() {
    if (!this.booking?.id || !(this.selectedPerformers.length > 0)) {
      return
    }

    this.updateSelectedPerformersLoading = true

    let res

    try {
      res = await this.rootAPI.bookingAPI.updateSelectedPerformers(
        getUpdateSelectedPerformersOptions(
          this.event.id,
          this.selectedPerformers
        )
      )

      if (isNotAllowedUpdateBooking(res)) {
        return this.createBooking({
          ...this.booking.customer,
          region: this.region,
          isSubmit: false,
        }).then(bookingResponse => {
          if (!isInvalidResponse(bookingResponse) || bookingResponse?.payload) {
            this.shouldUpdateBookingOnEnquiry = true

            return this.updateSelectedPerformers()
          }
        })
      }
    } catch (e) {
      this.rootStore.errorsStore.addError(e)
    } finally {
      this.updateSelectedPerformersLoading = false
    }

    return res
  }

  @action.bound
  async reorderSelectedPerformers() {
    if (!this.booking?.id || !(this.selectedPerformers.length > 0)) {
      return
    }

    this.reorderSelectedPerformersLoading = true

    let res

    try {
      res = await this.rootAPI.bookingAPI.updateSelectedPerformers(
        getUpdateSelectedPerformersOptions(
          this.event.id,
          this.selectedPerformers
        )
      )

      if (isNotAllowedUpdateBooking(res)) {
        return this.createBooking({
          ...this.booking.customer,
          region: this.region,
          isSubmit: false,
        }).then(bookingResponse => {
          if (!isInvalidResponse(bookingResponse) || bookingResponse?.payload) {
            this.shouldUpdateBookingOnEnquiry = true

            return this.reorderSelectedPerformers()
          }
        })
      }
    } catch (e) {
      this.rootStore.errorsStore.addError(e)
    } finally {
      this.reorderSelectedPerformersLoading = false
    }

    return res
  }

  @action.bound
  async setSelectedPerformers(selectedPerformers) {
    const selectedPerformersFallBack = [...this.selectedPerformers]
    this.selectedPerformers = selectedPerformers.filter(
      performer => !performer.placeholder
    )

    try {
      await this.reorderSelectedPerformers()
    } catch (e) {
      this.selectedPerformers = selectedPerformersFallBack
    }
  }

  @action.bound
  async getSelectedPerformers() {
    this.getSelectedPerformersLoading = true

    try {
      const { payload } = await this.rootAPI.bookingAPI.getSelectedPerformers({
        eventId: this.event.id,
      })

      if (payload) {
        this.selectedPerformers = payload.map(el => {
          const performer = this.performers.find(p => p.id === el.performerId)

          return {
            ...el,
            ...performer,
            id: el.performerId,
          }
        })
      }
    } catch (e) {
      this.rootStore.errorsStore.addError(e)
    } finally {
      this.getSelectedPerformersLoading = false
    }
  }

  @computed get isChooseForMe() {
    if (this.selectedPerformers?.length > 0) {
      return this.selectedPerformers.some(
        performer =>
          performer.alias?.toLowerCase() === 'choose for me' &&
          performer.selected
      )
    }

    return false
  }

  @computed get visiblePerformer() {
    return this.performers.find(performer => performer.visible) || {}
  }

  @computed get ceremonyVocalOption() {
    if (
      this.selectedCeremonyPerformance.id &&
      this.selectedCeremonyPerformance.id !== NO_CEREMONY
    ) {
      return get(
        this.selectedCeremonyPerformance,
        'vocalOption',
        VocalOption.NoVocals
      )
    }

    if (this.selectedCeremonyGig.id) {
      return get(
        this.selectedCeremonyGig,
        'performance.vocalOption',
        VocalOption.NoVocals
      )
    }

    return VocalOption.NoVocals
  }

  @computed get receptionVocalOption() {
    if (
      this.selectedReceptionPerformance.id &&
      this.selectedReceptionPerformance.id !== NO_RECEPTION
    ) {
      return get(
        this.selectedReceptionPerformance,
        'vocalOption',
        VocalOption.NoVocals
      )
    }

    if (this.selectedReceptionGig.id) {
      return get(
        this.selectedReceptionGig,
        'performance.vocalOption',
        VocalOption.NoVocals
      )
    }

    return VocalOption.NoVocals
  }

  @computed get vocalOption() {
    return mergeVocalOptions({
      ceremony: this.ceremonyVocalOption,
      reception: this.receptionVocalOption,
    })
  }

  @action.bound
  async getPerformers() {
    try {
      this.getPerformersLoading = true
      const { payload } = await this.rootAPI.bookingAPI.getPerformers({
        cityId: this.cityId,
      })

      if (payload && payload.length >= 0) {
        const sortedRes = payload.sort((a, b) => a.order - b.order)

        this.performers = [...sortedRes].map(p => {
          if (this.selectedPerformers.some(sp => sp.id === p.id)) {
            return {
              ...p,
              selected: true,
            }
          }

          return {
            ...p,
            selected: false,
          }
        })
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.getPerformersLoading = false
    }
  }

  @action.bound
  async updateSelectedPerformerList() {
    this.selectedPerformers = this.performers.reduce((ids, performer) => {
      if (performer.selected) {
        ids.push(performer)
      }

      return ids
    }, [])
  }

  @action.bound
  async toggleSelectPerformer(id, force = false) {
    this.performers = this.performers.map(performer => {
      if (performer.id === id) {
        if (!performer.selected) {
          this.performerOrder += 1

          return {
            ...performer,
            selected: true,
            selectedOrder: this.performerOrder,
          }
        }

        return {
          ...performer,
          selected: false,
          selectedOrder: 0,
        }
      }

      return performer
    })
    // this.updateSelectedPerformerList()
    const selectedPer = this.performers.find(per => per.id === id)
    const selectedPerIndex = this.selectedPerformers.findIndex(
      per => per.id === id
    )

    if (selectedPerIndex === -1) {
      this.selectedPerformers.push(selectedPer)
    } else {
      this.selectedPerformers.splice(selectedPerIndex, 1)
    }

    if (force) await this.updateSelectedPerformers()
    postAMessageFromIframeToParent('IsSelectedItemOnSingerPage', true)
  }

  @observable performerModalVisible = false

  @action.bound
  togglePerformerModal({ id, visible }) {
    this.performerModalVisible = visible
    this.performers = this.performers.map(performer => {
      if (performer.id === id) {
        return {
          ...performer,
          visible,
        }
      }

      return performer
    })
  }

  @action.bound
  async toggleChooseForMe(value) {
    this.performers = this.performers.map(performer => ({
      ...performer,
      selected: performer.id === 'choose-for-me' ? value : false,
    }))
  }

  @observable submitPerformersLoading = false

  // Enquiry
  @persist('object') @observable enquiry = {}

  @observable submitEnquiryLoading = false

  @observable submitEnquiryType = -1

  @observable successInfo = {
    over178kmMessage: '',
    hasPerformerSurcharge: false,
  }

  @persist @observable eventNote = ''

  @persist @observable isEventNoteChanged = false

  @action.bound
  setNote(eventNote) {
    this.eventNote = eventNote
  }

  @action.bound
  async submitEnquiry({
    validatePerformers,
    submitEnquiryType,
    dontRedirect,
    isCreateNewEnquiry,
  }) {
    let success = false

    if (
      !this.selectedCeremonyPerformance.id &&
      !this.selectedReceptionPerformance.id
    ) {
      this.rootStore.alertStore.error({
        title: 'Whoops!',
        content: `Please select a music option for your ${this.isPrivateEventBooking ? 'background and dance floor music' : 'ceremony & reception'}`,
      })

      return success
    }

    let promises = []

    if (this.isChooseForMe) {
      promises = [
        this.rootAPI.bookingAPI.updateSelectedPerformers(
          getUpdateSelectedPerformersOptions(
            this.event.id,
            this.selectedPerformers
          )
        ),
      ]
    } else if (validatePerformers) {
      const { vocalOption } = this

      if (
        vocalOption !== VocalOption.NoVocals &&
        this.selectedPerformers.length < 3
      ) {
        this.rootStore.errorsStore.addError({
          description: 'Please select at least 3 singers',
        })

        return success
      }

      if (this.selectedPerformers.length) {
        promises = [
          this.rootAPI.bookingAPI.updateSelectedPerformers(
            getUpdateSelectedPerformersOptions(
              this.event.id,
              this.selectedPerformers
            )
          ),
        ]
      }
    }

    try {
      this.submitEnquiryType = submitEnquiryType
      this.submitEnquiryLoading = true

      if (promises && promises.length > 0) {
        await Promise.all(promises)
      }

      const { customer } = this.booking
      const bookingData = {
        ...customer,
        region: this.region,
        isSubmit: isCreateNewEnquiry,
      }

      if (this.shouldUpdateBookingOnEnquiry) {
        const res = await this.rootAPI.bookingAPI.submitEnquiry({
          eventId: this.event.id,
          note: this.booking.eventNote,
          gaCustomerId: window?.gaGlobal?.vid ?? '',
        })

        if (isNotAllowedUpdateBooking(res)) {
          this.createBooking(bookingData)
        }

        this.shouldUpdateBookingOnEnquiry = false
      } else {
        this.createBooking(bookingData)
      }

      success = true

      runInAction(() => {
        this.successInfo = {
          over178kmMessage: this.over178kmMessage,
          over500kmMessage: this.over500kmMessage,
          hasPerformerSurcharge: this.selectedPerformers.some(
            performer => Number(performer.surcharge) > 0
          ),
        }

        // this.clear()
        if (!dontRedirect) {
          this.push(BookingQuoteStagesPaths.success)
        }
      })
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.submitEnquiryLoading = false
    }

    return success
  }

  // Summary modal
  @observable summaryModalVisible = false

  @action.bound
  toggleSummaryModal({ visible }) {
    this.summaryModalVisible = visible
  }

  // Singers modal
  @observable singersModalVisible = false

  @action.bound
  toggleSingersModalVisible() {
    this.pauseOtherVideos()
    this.singersModalVisible = !this.singersModalVisible
    setTimeout(() => {
      if (!this.isOpenedWeddingLocationModal) {
        this.toggleWeddingLocationModalVisible()
      }
    }, 500)
  }

  // Ceremony music modal
  @observable ceremonyMusicModalVisible = false

  @action.bound
  toggleCeremonyMusicModalVisible() {
    this.pauseOtherVideos()
    this.ceremonyMusicModalVisible = !this.ceremonyMusicModalVisible
    setTimeout(() => {
      if (!this.isOpenedWeddingLocationModal) {
        this.toggleWeddingLocationModalVisible()
      }
    }, 500)
  }

  // Ceremony music Group modal
  @observable ceremonyMusicGroupModalVisible = false

  @action.bound
  toggleCeremonyMusicGroupModalVisible() {
    this.pauseOtherVideos()
    this.ceremonyMusicGroupModalVisible = !this.ceremonyMusicGroupModalVisible
  }

  // Reception music Group modal
  @observable receptionMusicModalVisible = false

  @action.bound
  toggleReceptionMusicModalVisible() {
    this.pauseOtherVideos()
    this.receptionMusicModalVisible = !this.receptionMusicModalVisible
    setTimeout(() => {
      if (!this.isOpenedWeddingLocationModal) {
        this.toggleWeddingLocationModalVisible()
      }
    }, 500)
  }

  // Get A Price Modal
  @observable getPriceModalVisible = false

  @action.bound
  toggleGetPriceModalVisible(isVisible = !this.getPriceModalVisible) {
    this.pauseOtherVideos()
    this.getPriceModalVisible = isVisible
    setTimeout(() => {
      if (!this.isOpenedWeddingLocationModal) {
        this.toggleWeddingLocationModalVisible()
      }
    }, 500)
  }

  // Wedding Location modal
  @observable weddingLocationModalVisible = false

  @action.bound
  toggleWeddingLocationModalVisible() {
    this.pauseOtherVideos()
    this.weddingLocationModalVisible = !this.weddingLocationModalVisible
    this.toggleOpenedWeddingLocationModal()
  }

  // confirm Submit Enquiry success Modal
  @observable confirmSubmitEnquirySuccessModalVisible = false

  @action.bound
  toggleSubmitEnquirySuccessModalVisible() {
    this.pauseOtherVideos()
    this.confirmSubmitEnquirySuccessModalVisible =
      !this.confirmSubmitEnquirySuccessModalVisible
  }

  @persist
  @observable
  isOpenedWeddingLocationModal = false

  @action.bound
  toggleOpenedWeddingLocationModal() {
    this.isOpenedWeddingLocationModal = true
  }

  pauseOtherVideos = () => {
    const streams = document.getElementsByTagName('stream')

    if (streams && streams.length) {
      Array.prototype.forEach.call(streams, stream => {
        if (typeof stream.pause === 'function') {
          stream.pause()
        }
      })
    }
  }

  // Prev/Next
  @observable next = ''

  @observable prev = ''

  @observable nextLoadingProp = ''

  @observable prevLoadingProp = ''

  @action.bound
  setNext(next) {
    this.next = next
  }

  @action.bound
  setPrev(prev) {
    this.prev = prev
  }

  @action.bound
  setNextLoading(nextLoadingProp) {
    this.nextLoadingProp = nextLoadingProp
  }

  @computed get nextLoading() {
    if (typeof this.nextLoadingProp === 'boolean') {
      return (
        this.submitEnquiryLoading &&
        this.submitEnquiryType === SubmitEnquiryType.OnPerformerPage
      )
    }

    return this[this.nextLoadingProp] || false
  }

  @persist('list') @observable pushedGAEvents = []

  @action.bound
  addPushedGAEvents(page) {
    this.pushedGAEvents = [...this.pushedGAEvents, page]
  }

  @action.bound
  clearPushedGAEvents() {
    this.pushedGAEvents = []
  }

  @action.bound
  clearPushedGASuccessPage() {
    const hasSuccess = this.pushedGAEvents.some(
      el => el.indexOf(BookingQuoteStagesPaths.success) >= 0
    )

    if (hasSuccess) {
      this.pushedGAEvents = this.pushedGAEvents.filter(
        el => el.indexOf(BookingQuoteStagesPaths.success) < 0
      )
    }
  }

  @action.bound
  async getBookingById(bookingId = this.booking.id) {
    try {
      const res = await this.rootAPI.bookingAPI.getBookingById(bookingId)

      if (res) {
        this.booking = res.payload
      }
    } catch (e) {
      this.rootStore.errorsStore.addError(e)
    }
  }

  @action.bound
  setGigDuration(gigType, duration) {
    this[`${gigType}Duration`] = duration
  }

  @action.bound
  async updateGigDuration(gigType, duration) {
    // gigType: 'ceremony' or 'reception'
    const gigId = this[`${gigType}Gig`].id
    this.setGigDuration(gigType, duration)

    if (!this.booking || !this[`${gigType}Gig`].id) return

    const bookingFallback = cloneDeep(this.booking)

    try {
      const { type, code, payload } =
        await this.rootAPI.bookingAPI.updateGigDuration(gigId, { duration })

      if (isNotAllowedUpdateBooking({ code, type })) {
        return this.createBooking({
          ...this.booking.customer,
          region: this.region,
          isSubmit: false,
        }).then(bookingResponse => {
          if (!isInvalidResponse(bookingResponse) || bookingResponse?.payload) {
            this.shouldUpdateBookingOnEnquiry = true

            return this.updateGigDuration(gigType, duration)
          }
        })
      }

      if (type >= RESPONSE_TYPES.OK && payload) {
        runInAction(() => {
          this.setQuote(payload)
        })
      }

      await this.getPerformances()
    } catch (e) {
      handleQuoteBuilderError(e)
      runInAction(() => {
        this.booking = bookingFallback
      })
    }
  }

  @observable isFixedNavbar = false

  @observable fixedNavbarOffset = 0

  @action.bound
  setIsFixedNavbar(bool) {
    this.isFixedNavbar = bool
  }

  @action.bound
  setFixedNavbarOffset(value) {
    this.fixedNavbarOffset = value
  }

  // booking sheet
  @observable bookingSheet = {}

  @observable getBookingSheetLoading = false

  @computed get bookedMusicians() {
    const positionMaps = get(this.bookingSheet, 'positionMaps', {})
    const gigs = get(this.bookingSheet, 'booking.event.gigs', [])

    const gigMap = new Map(gigs.map(gig => [gig.id, gig.performance]))

    return Object.entries(positionMaps)
      .map(([gigId, positions]) => ({
        ...gigMap.get(gigId),
        positions,
      }))
      .sort(a => {
        if (a?.type?.name === PerformanceType.Ceremony) {
          return -1
        }

        return 1
      })
  }

  @computed get ceremonyBookedMusician() {
    return this.bookedMusicians.find(
      musician => musician.type?.name === PerformanceType.Ceremony
    )
  }

  @computed get receptionBookedMusician() {
    return this.bookedMusicians.find(
      musician => musician.type?.name === PerformanceType.Reception
    )
  }

  @computed get totalMusicianPay() {
    return (this.bookedMusicians || [])
      .map(musician => musician?.positions)
      .flat()
      .reduce((acc, position) => acc + position?.earning || 0, 0)
  }

  @action.bound
  async getBookingSheet({
    bookingId,
    customerId,
    roleType,
    onSuccess,
    onError,
  }) {
    this.getBookingSheetLoading = true

    try {
      const res = await this.rootAPI.bookingAPI.getBookingSheet(bookingId, {
        customerId,
        roleType,
      })

      if (res.payload) {
        runInAction(() => {
          this.bookingSheet = res.payload
        })
        onSuccess?.(res.payload)
      }
    } catch (err) {
      onError?.(err)
    } finally {
      this.getBookingSheetLoading = false
    }
  }

  @persist @observable ceremonyBookingMinHour = 1

  @action.bound
  async calculateBookingAddressDistance(location) {
    let distance = 0

    try {
      if (location) {
        const { payload } = await this.rootAPI.venuesAPI.getVenuesDistance(
          this.cityId,
          location
        )
        distance = payload || 0
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }

    return distance
  }

  @action.bound
  async checkCeremonyBookingMinDuration(location) {
    try {
      if (
        isEmptyObject(this.selectedReceptionPerformance) ||
        this.selectedReceptionPerformance.id === NO_RECEPTION
      ) {
        const distance = await this.calculateBookingAddressDistance(location)
        const ceremonyMinHour = CeremonyMinHourByDistance.findLast(
          item => distance >= item.minDistance
        ).minHour

        this.ceremonyBookingMinHour = ceremonyMinHour

        if (ceremonyMinHour > this.ceremonyDuration) {
          this.setGigDuration('ceremony', ceremonyMinHour)
        }
      } else {
        this.ceremonyBookingMinHour = InitialHourBooking.ceremony.min
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    }
  }

  @action.bound
  async resetMinimumBookingHoursOnCeremony() {
    this.ceremonyBookingMinHour = InitialHourBooking.ceremony.min
  }

  @observable isReconfirmBookingStatus = null

  @action.bound
  async reConfirmBooking(groupOfferId, isConfirm) {
    this.isReconfirmBookingStatus = isConfirm

    const {
      offerStore: { getFutureBookings },
      profileStore: { userId },
    } = this.rootStore

    try {
      await this.rootAPI.bookingAPI.reConfirmBooking(groupOfferId, isConfirm)

      if (!isConfirm) {
        this.rootStore.routingStore.push('/musician/bookings')
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      getFutureBookings(userId)
      this.isReconfirmBookingStatus = null
    }
  }

  // Clean up
  @action.bound
  clear(option = { clearPerformances: true, clearPerformers: true }) {
    const { clearPerformances, clearPerformers } = option || {}

    if (clearPerformances) {
      this.ceremonyPerformances = []
      this.receptionPerformances = []
    }

    if (clearPerformers) {
      this.selectedPerformers = []
    }

    this.booking = {}
    this.quote = {}
    this.date = ''
    this.notSureWhen = false
    this.ceremonyLocation = ''
    this.ceremonyPlaceName = null
    this.ceremonyRawData = null
    this.receptionLocation = ''
    this.receptionPlaceName = null
    this.receptionRawData = null
    this.over178kmMessage = ''
    this.over500kmMessage = ''
    this.notSureCeremonyLocation = false
    this.notSureReceptionLocation = false
    this.showLocationModalTimes = 0
    this.savedNotSureCeremonyLocation = false
    this.savedNotSureReceptionLocation = false
    this.isCustomisation = false
    this.ceremonyPerformanceGig = {}
    this.receptionPerformanceGig = {}
    this.performers = []
    this.performerOrder = 0
    this.enquiry = {}
    this.eventNote = ''
    this.pushedGAEvents = []
    this.shouldUpdateBookingOnEnquiry = true
    this.region = REGIONS.AU
    this.bookingSheet = {}
  }
}
